<template>
  <a-modal
    v-model:visible="show"
    destroy-on-close
    width="1000px"
    :mask-closable="false"
    @cancel="cancel"
    :title="title"
  >
    <a-form
      class="myform"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      :rules="rules"
      :model="record"
      ref="formRef"
    >
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Version"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            has-feedback
            name="version"
          >
            <a-input
              width="100%"
              v-model:value="record.version"
              :disabled="readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <!-- <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Title"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            has-feedback
            name="title"
          >
            <a-input
              width="100%"
              v-model:value="record.title"
              :disabled="readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row> -->
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item 
            label="Deskripsi"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            name="deskripsi"
            >
          <quill-editor
            class="mb-2"
            v-model:content="record.deskripsi"
            content-type="html"
            toolbar="full"
            :read-only="readOnly"
          />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item 
            label="Tanggal Rilis"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            name="date_rilis"
            >
          <a-date-picker
            placeholder="Tanggal Rilis"
            v-model:value="record.date_rilis"
            value-format="YYYY-MM-DD"
            :disabled="readOnly"
            required
          />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Status"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            name="is_active"
          >
            <a-radio-group
              :disabled="readOnly"
              v-model:value="record.is_active"
              required
            >
              <a-radio :value="true">Aktif</a-radio>
              <a-radio :value="false">Tidak Aktif</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <template #footer>
      <a-button key="submit" @click="submit" v-if="!readOnly" type="primary">Simpan</a-button>
      <a-button key="cancel" @click="show = false">Cancel</a-button>
    </template>
  </a-modal>
</template>

<script>
import { inject, toRefs, ref } from 'vue'

import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import { serialize } from 'object-to-formdata'
import rules from './rules'

export default {
  setup() {
    const show = inject('show')
    const showState = inject('showState')
    showState.record = Object.assign({}, showState.record)
    const refresh = inject('refresh')
    const formRef = ref()

    const submit = async e => {
      try {
        await formRef.value.validate()
      } catch (error) {
        console.error(error)
        return
      }

      if (showState.state === 'edit') {
        ubahVersionRilis(showState.record)
      }
      if (showState.state === 'new') tambahVersionRilis(showState.record)
    }
    const cancel = e => {
      show.value = false
    }

    const tambahVersionRilis = record => {
      Modal.confirm({
        title: 'Konfirmasi Tambah Version Rilis',
        content: 'Apakah anda ingin melanjutkan Tambah Version Rilis ?',
        onOk() {
          const form_data = serialize(record, { booleansAsIntegers: true })

          apiClient
            .post('/api/version-rilis', form_data)
            .then(({ data }) => {
              show.value = false
              refresh()
            })
            .catch(error => {
              // message.error('Gagal menambah!')
              if (error.response && error.response.data) {
                message.error(error.response.data[0].message)
              }
            })
        },
        onCancel() {},
      })
    }

    const ubahVersionRilis = record => {
      Modal.confirm({
        title: 'Konfirmasi Ubah Version Rilis',
        content: 'Apakah anda ingin melanjutkan Ubah Version Rilis ?',
        onOk() {
          const form_data = serialize(record, { booleansAsIntegers: true })
          apiClient
            .post('/api/version-rilis/' + record.id + '?_method=PUT', form_data)
            .then(({ data }) => {
              show.value = false
              refresh()
            })
            .catch(error => {
              // message.error('Gagal mengupdate!')
              if (error.response && error.response.data) {
                message.error(error.response.data[0].message)
              }
            })
        },
        onCancel() {},
      })
    }

    return { visible: true, show, submit, cancel, ...toRefs(showState), rules, formRef }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
